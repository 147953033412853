import { getPulse } from '@innhold/core/tracking/pulse';

export async function trackTickerClick(tickerSymbol: string) {
    const pulse = await getPulse();
    pulse.trackElementClick({
        object: {
            id: 'ticker',
            type: 'stock-stripe',
            name: tickerSymbol,
        },
    });
}
